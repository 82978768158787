import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '@constants/api-routes';
import {
  ForgottenPasswordChangeRequest,
  ForgottenPasswordChangeResponse,
  ObtainTokenPostRequest,
  ObtainTokenPostResponse,
  PasswordRestoreRequest,
  PasswordRestoreResponse,
  RefreshToken,
  RefreshTokenPostResponse,
} from '@models/auth.model';
import { RefreshSubjectEntity } from '@services/data-access/_core/trigger-entities.service';
import { Observable, tap } from 'rxjs';
import { addAuthorizationHeader } from '../../../interceptors/utils/auth.utils';
import { CoreApiService } from '../_core/core-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends CoreApiService {
  protected entityName: RefreshSubjectEntity = RefreshSubjectEntity.Auth;

  postRefreshToken(
    refresh: RefreshToken['refresh'],
  ): Observable<RefreshTokenPostResponse> {
    return this.http.post<RefreshTokenPostResponse>(API_ROUTES.token.refresh, {
      refresh,
    });
  }

  postObtainToken(
    body: ObtainTokenPostRequest,
  ): Observable<ObtainTokenPostResponse> {
    return this.http.post<ObtainTokenPostResponse>(API_ROUTES.token.pair, body);
  }

  changeForgottenPassword(
    body: ForgottenPasswordChangeRequest,
  ): Observable<ForgottenPasswordChangeResponse> {
    return this.http.post<ForgottenPasswordChangeResponse>(
      API_ROUTES.users.forgotPassword,
      body,
    );
  }

  restorePassword(
    body: PasswordRestoreRequest,
    token: string,
  ): Observable<PasswordRestoreResponse> {
    const headers = new HttpHeaders();

    return this.http
      .post<PasswordRestoreResponse>(API_ROUTES.users.restorePassword, body, {
        ...addAuthorizationHeader(headers, token),
      })
      .pipe(
        tap(() =>
          this.snackbarService.add({
            message: 'PASSWORD_CHANGED',
            color: 'success',
          }),
        ),
      );
  }
}
